import React from 'react';
import OrderTicketUserList from '../components/order_ticket_user_list';
import OrderAddOnList from '../components/order_add_on_list';
import { useSelector } from 'react-redux';
import PurchaseButton from '../components/purchase_button';
import { Checkbox, Col, Divider, Row, Space } from 'antd';
import PromoCode from '../components/promo_code';
import EntranceMotion from '../../../helpers/components/entrance_motion';
import PurchaseTaxes from '../components/purchase_taxes';
import ticketService from '../../../services/ticketing/ticket.service';
import numberHelper from '../../../helpers/functions/number.helper';
import PromoCodeDefault from '../components/promo_code_default';

const MobilePurchaseSummary = ({park, amount}) => {
    const ticketRedux = useSelector(state => state.ticket);
    const purchaseRedux = useSelector(state => state.purchase);
    const currency = ticketRedux?.park?.currency?.symbol;
    const { innerHeight } = window;
    const is_item_length = (purchaseRedux?.ticket_users?.length > 0 || purchaseRedux?.add_ons?.length > 0);   

    return (
        <>
            {/* <div style={{height: (innerHeight * 0.6),}}> */}
            <div style={{height: ('calc(100% - 240px)'),}}>
                <div
                className='summary-cart-items scroll-item' 
                style={{
                    overflowY:'auto',
                    flexGrow : 1, 
                    maxHeight: 'calc(100%)',
                    height : (is_item_length ? 'auto' : '100%')
                }}
                >
                {
                    (is_item_length) ?
                    (
                        <>
                            <OrderTicketUserList discount_ticket_users={amount?.discount_ticket_users} />
                            <OrderAddOnList discount_add_ons={amount?.discount_add_ons} />
                        </>
                    )
                    :
                    (
                        <>
                            <div style={{display:'flex', alignItems:'center', justifyContent:'center', height:'100%'}}>
                                <span style={{color:'#6f7287'}}>No Item Added</span>
                            </div>
                        </>
                    )
                }
                </div>
            </div>

            <div className='purchase-modal-footer' style={{textAlign:'left', padding:'12px 24px', position:'unset', width:'auto', maxHeight:240}}>
                <Space size={4} style={{width:'100%'}} direction='vertical'>
                    <div>
                        {
                            ticketRedux?.default_promo_code ?
                            (
                                <>
                                    <PromoCodeDefault />
                                </>
                            )
                            :
                            (
                                <>
                                    <PromoCode />
                                </>
                            )
                        }
                    </div>

                    <div style={{marginTop:8,}}>
                        <EntranceMotion>
                            <div style={{ display:'flex', alignItems:'center', }}>
                                <div style={{ textAlign:'right'}}>
                                    <span style={{color:'var(--secondary-text-color)'}}>Sub Total</span>
                                </div>
                                <div style={{marginLeft:'auto'}}>
                                    <div>
                                        <span className='ticket-label'>
                                            {`${currency}${numberHelper.commaNumber(amount?.sub_total)}`}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div style={{ display:'flex', alignItems:'center', }}>
                                <div>
                                    <div style={{ textAlign:'right'}}>
                                        <span style={{color:'var(--secondary-text-color)'}}>Discount</span>
                                    </div>
                                </div>
                                <div style={{marginLeft:'auto'}}>
                                    <div>
                                        <span className='ticket-label' style={{ color : 'var(--main-color)'}}>
                                            {`-${currency}${numberHelper.commaNumber(amount?.discount_amount)}`}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </EntranceMotion>
                    </div>
                    
                    <div>
                        <PurchaseTaxes park_taxes={park?.park_taxes} taxes={amount?.taxes} />
                    </div>

                    <div style={{ display:'flex', alignItems:'center' }}>
                        <div>
                            <div style={{ textAlign:'right'}}>
                                <span style={{color:'var(--secondary-text-color)'}}>Total</span>
                            </div>
                        </div>
                        <div style={{marginLeft:'auto'}}>
                            <div>
                                <span className='ticket-label' style={{fontSize:20, fontWeight:500}}>
                                    {`${currency}${numberHelper.commaNumber(amount?.total_amount)}`}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div style={{marginTop:8,}}>
                        <PurchaseButton final_price={amount?.total_amount} park={park} is_checkout />
                    </div>
                </Space>
            </div>
        </>
    );
}

export default MobilePurchaseSummary;